import { checkCarInRace } from './api';
import Cookies from 'js-cookie';
import config from '../config';

const API_BASE_URL = config.apiBaseUrl;

// Função para obter detalhes do NFT
const getNftDetailsFromAtomicHub = async (nftId) => {
  const ATOMIC_HUB_API_URL = 'https://wax.api.atomicassets.io/atomicassets/v1/assets/';
  try {
    const response = await fetch(`${ATOMIC_HUB_API_URL}${nftId}`);
    const data = await response.json();

    if (!data || !data.data) {
      throw new Error('Dados do NFT não encontrados');
    }

    const attributes = data.data.template.immutable_data;

    return {
      id: nftId,
      name: attributes.name,
      img: attributes.img,
      SpeedLevel: parseInt(attributes.SpeedLevel) || 0,
      AccelerationLevel: parseInt(attributes.AccelerationLevel) || 0,
      NitroLevel: parseInt(attributes.NitroLevel) || 0,
      LuckLevel: parseInt(attributes.LuckLevel) || 0,
      CarRarity: attributes.CarRarity || 'Common',
      SkinRarity: attributes.SkinRarity || 'Common'
    };
  } catch (error) {
    console.error('Erro ao obter detalhes do NFT do Atomic Hub:', error);
    throw new Error('Falha ao obter detalhes do NFT do Atomic Hub.');
  }
};

export const startRace = async (
  selectedCar,
  selectedMap,
  userAccount,
  carUsage,
  setCarUsage,
  setRaceTime,
  setErrorMessage,
  setRacesCompleted,
  resetSelectedCar,
  equippedNFTs,
  resetEquippedNFTs
) => {
  try {
    console.log('Iniciando o processo de corrida no backend...');
    
    // Validações iniciais
    if (!selectedCar) throw new Error('Nenhum carro selecionado.');
    if (!selectedMap) throw new Error('Nenhum mapa selecionado.');

    const carId = selectedCar.asset_id;
    const carTemplateId = selectedCar.template_id || selectedCar.template?.template_id;
    const carImageUrl = selectedCar.template?.immutable_data?.img2;
    const mapImageUrl = selectedMap.image;

    if (!carTemplateId) throw new Error('ID do template do carro não encontrado.');
    if (!carImageUrl) throw new Error('URL da imagem do carro não encontrada.');
    if (!mapImageUrl) throw new Error('URL da imagem do mapa não encontrada.');

    console.log('Verificando status da corrida no backend para o carro:', carId);
    const raceStatus = await checkCarInRace(carId);
    console.log('Status da corrida retornado:', raceStatus);

    if (raceStatus.inRace) {
      throw new Error('O carro já está em uma corrida em andamento.');
    }

    const currentTime = new Date().toISOString();
    const usageInfo = carUsage[carId] || { count: 0, lastUsed: currentTime };

    // Check car usage limit (if relevant to your game logic)
    if (usageInfo.count >= 10 && new Date().getTime() - new Date(usageInfo.lastUsed).getTime() < 12 * 60 * 60 * 1000) {
      throw new Error('Limite de uso deste carro atingido.');
    }

    // Obtenção de tokens e detalhes do NFT
    const authToken = Cookies.get('authToken');
    const csrfToken = Cookies.get('csrfToken');
    if (!authToken || !csrfToken) {
      throw new Error('Autenticação falhou. Por favor, faça login novamente.');
    }

    console.log('Obtendo detalhes do carro NFT do Atomic Hub:', carId);
    const carNftDetails = await getNftDetailsFromAtomicHub(carId);
    if (!carNftDetails) {
      throw new Error('Erro ao obter detalhes do NFT do carro.');
    }

    // Preparar dados da corrida
    const itemsWithIds = equippedNFTs.map(item => ({
      asset_id: item.asset_id,
      collectionName: item.collection.collection_name,
      templateId: item.template.template_id,
      victoryChance: item.victoryChance,
      dropChance: item.dropChance,
      xpBonus: item.xpBonus || 0
    }));

    const raceData = {
      walletAddress: userAccount,
      carId,
      carTemplateId,
      carImageUrl,
      mapId: selectedMap._id,
      mapImageUrl,
      startTime: currentTime,
      duration: selectedMap.raceTime,
      carNftDetails,
      equippedItems: itemsWithIds
    };

    console.log('Enviando solicitação de início de corrida:', raceData);

    const response = await fetch(`${API_BASE_URL}/api/protected/start-race`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify(raceData),
      credentials: 'include'
    });

    console.log('Resposta bruta do backend:', response);

    // Verificar se a resposta foi bem-sucedida
    if (!response.ok) {
      const errorData = await response.json();
      console.error(`Erro no backend: ${response.status} - ${errorData.message}`);
      throw new Error(errorData.message || 'Falha ao iniciar a corrida.');
    }

    const data = await response.json();
    console.log('Dados da corrida iniciada recebidos do backend:', data);

    // Resetar estados no sucesso
    resetSelectedCar();
    resetEquippedNFTs();
    setRaceTime(selectedMap.raceTime);
    setCarUsage({
      ...carUsage,
      [carId]: {
        count: usageInfo.count + 1,
        lastUsed: new Date().toISOString(),
        raceTime: selectedMap.raceTime
      }
    });
    setRacesCompleted(data.racesCompleted);

    // Retornar os dados da corrida iniciada
    return data;

  } catch (error) {
    console.error('Erro ao iniciar a corrida:', error.message);
    // Repassar o erro para ser tratado no handleStartRace
    throw error;
  }
};
